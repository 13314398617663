<template>
    <div style="width: 500px; margin: auto; margin-top: 200px" class="box" id="login">
        <h1 class="title">Login</h1>
        <div v-if="user_or_pw_wrong" class="field">
            <strong class="has-text-danger">username or password wrong...</strong>
        </div>
        <div class="field control">
            <input class="input" type="text" name="username" v-model="input.username" placeholder="Username" />
        </div>
        <div class="field control">
            <input class="input" type="password" name="password" v-on:keyup.enter="login()" v-model="input.password" placeholder="Password" />
        </div>
        <div class="field control">
            <b-switch v-model="input.remember">Remember</b-switch>
        </div>
        <button class="button is-primary" type="button" v-on:click="login()">Login</button>
    </div>
</template>

<script>
    import HOST from './host.js';
    export default {
        name: 'Login',
        data() {
            return {
                input: {
                    username: "",
                    password: "",
                    remember: false
                },
                user_or_pw_wrong: false
            }
        },
        methods: {
            async login() {
                if(this.input.username != "" && this.input.password != "") {
                    const settings = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({email: this.input.username, pw: this.input.password, remember: this.input.remember})
                        }
                    let response = await fetch(`${HOST}/login`, settings);

                    if(response.status == 403) {
                        this.user_or_pw_wrong = true;
                    } else if(response.redirected) {
                        window.location.href = (await response.url);
                        window.location.reload();
                    }
                } else {
                    this.user_or_pw_wrong = true;
                }
            }
        }
    }
</script>
