import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import rootElement from './login.vue';

Vue.use(Buefy, {
    defaultIconPack: 'fas'
});

let vm = new Vue({
    el: 'body',
    render: (h) => h(rootElement),
}); 
